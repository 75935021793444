import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  List,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
  Removed,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="July 2018" subnav="release-notes">
      <div id="July2018" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          We’re switching things up to bring you full release notes at the end
          of every month! Because keeping track of what happened the first two
          weeks of one and the last two weeks of another was getting to be a bit
          much, and it’s hard to get excited about a release when you’re not
          totally sure where in the middle of the month it might fall. But
          enough about us—let’s talk what’s new.
        </Paragraph>

        <Paragraph>
          Three (very common) components are ready to make their mark on the
          Hudl interface: keyboard shortcut, spinner and notice. We moved a ton
          of CSS styling from uniCSS to uniComponents, and threw so many
          improvements at every select, they may as well be brand new. Read on
          for more.
        </Paragraph>

        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                Guidelines for the new{' '}
                <Link href="/components/keyboard-shortcut" isDesignCodeLink>
                  keyboard shortcut
                </Link>
                .
              </Added>
              <Added>
                How and when to use the{' '}
                <Link href="/components/spinner" isDesignCodeLink>
                  spinner
                </Link>{' '}
                component.
              </Added>
              <Added>
                Everything there is to know about{' '}
                <Link href="/components/notifications/notice" isDesignCodeLink>
                  notices
                </Link>
                .
              </Added>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="3.16.0"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v3.16.0"
            />

            <div className={styles.changesList}>
              <Added>
                The{' '}
                <Link href="/components/keyboard-shortcut" isDesignCodeLink>
                  keyboard shortcut
                </Link>{' '}
                component.
              </Added>
              <Added>
                Our new and improved{' '}
                <Link href="/components/spinner" isDesignCodeLink>
                  spinner
                </Link>
                .
              </Added>
              <Added>
                That{' '}
                <Link href="/components/notifications/notice" isDesignCodeLink>
                  notice
                </Link>{' '}
                you’ve been waiting for.
              </Added>
              <Added>All kinds of styling pulled from CSS.</Added>
              <Added>
                Props!
                <List>
                  <li>
                    <code>onRemoveAllItems</code> for{' '}
                    <Link
                      href="/components/selects/lookup-multi-select"
                      isDesignCodeLink>
                      lookup multi-select
                    </Link>
                  </li>
                  <li>
                    <code>qaId</code> for more components than we can name
                  </li>
                </List>
              </Added>
              <Added>
                A dismiss button for{' '}
                <Link href="/components/selects/single-select" isDesignCodeLink>
                  single select
                </Link>{' '}
                so a user can deselect an option.
              </Added>
              <Improved>
                <Link href="/components/action-bar" isDesignCodeLink>
                  Action bar
                </Link>{' '}
                actions to exist as a shape rather than a separate component.
              </Improved>
              <Improved>
                Help text to include bold and italics, even links.
              </Improved>
              <Improved>
                <Link href="/components/icon" isDesignCodeLink>
                  Icons
                </Link>{' '}
                can pass in custom icons in as childen for the a11y and icon
                label benefits.
              </Improved>
              <Fixed>
                <Link href="/components/selects/lookup-select" isDesignCodeLink>
                  Lookup select
                </Link>{' '}
                not filtering when <code>showAllOptions</code> was set to{' '}
                <code>true</code>.
              </Fixed>
              <Fixed>
                An issue where clicks didn't register on certain parts of the
                lookup select and single select.
              </Fixed>
              <Fixed>
                The thing where an action could not be disabled in the{' '}
                <Link href="/components/action-bar" isDesignCodeLink>
                  action bar
                </Link>
                .
              </Fixed>
              <Fixed>
                Max-height on{' '}
                <Link href="/components/selects/lookup-select" isDesignCodeLink>
                  lookup select
                </Link>
                .
              </Fixed>
              <Fixed>
                An issue with scrolling to the top when selecting an item in{' '}
                <Link
                  href="/components/selects/lookup-multi-select"
                  isDesignCodeLink>
                  lookup multi-select
                </Link>
                .
              </Fixed>
              <Fixed>
                <Link
                  href="/components/selects/lookup-multi-select"
                  isDesignCodeLink>
                  Lookup multi-select
                </Link>{' '}
                (again) to display the placeholder.
              </Fixed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="CSS"
              version="1.11.0"
              versionUrl="https://github.com/hudl/uniform-ui-css/releases/tag/v1.11.0"
            />

            <div className={styles.changesList}>
              <Added>Tokens for utility colors.</Added>
              <Improved>
                <code>classes</code> to use those new color tokens.
              </Improved>
              <Improved>
                Overflow mask and content padding for the{' '}
                <Link href="/components/dialogs/modal" isDesignCodeLink>
                  modal
                </Link>
                .
              </Improved>
              <Fixed>The double scrolling issue with groups in selects.</Fixed>
              <Removed>
                Styling for pretty much everything and put it all in
                uniComponents:
                <List>
                  <li>Avatars</li>
                  <li>Action List</li>
                  <li>Action Bar</li>
                  <li>Tooltip</li>
                  <li>Notice</li>
                  <li>Spinner</li>
                  <li>Toast</li>
                  <li>Modal</li>
                  <li>Selects</li>
                  <li>SelectMark</li>
                </List>
              </Removed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader libName="Sketch Library" />

            <div className={styles.changesList}>
              <Added>That keyboard shortcut.</Added>
              <Added>The spinner, too.</Added>
              <Added>A notice component—but you already knew that.</Added>
              <Added>
                New effects icons:
                <List>
                  <li>Arrow</li>
                  <li>Circle</li>
                  <li>Cursor</li>
                  <li>Freehand</li>
                  <li>Text</li>
                </List>
              </Added>
              <Added>
                Native text styles! (The plugin is so two months ago.)
              </Added>
              <Improved>The naming scheme for level symbols.</Improved>
              <Fixed>Checkboxes and radios to match production.</Fixed>
              <Fixed>
                Normal Play and Auto Skip symbol names to match the icons.
              </Fixed>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
